import {Injectable} from "@angular/core";
import {REFERRER_URL} from "../consts";

@Injectable({ providedIn: 'root' })
export class MultibookUserService {
  init(){
    if(!sessionStorage.getItem(REFERRER_URL)){
      sessionStorage.setItem(REFERRER_URL,document.referrer)
    }
  }

  isMultibookUser(): boolean{
    const referrerUrl = sessionStorage.getItem(REFERRER_URL);
    if(referrerUrl){
      return (referrerUrl as string).includes('https://multibook.lecta.ru') || (referrerUrl as string).includes('https://x3-apps.prosv.ru');
    }
    return  false;
  }

}
