import { UserRoleTitle } from './interface';
import { UserRole } from './const';

export function getUserRoleTitle(role: UserRole): UserRoleTitle {
  switch (role) {
    case UserRole.student:
      return 'student';
    case UserRole.teacher:
      return 'teacher';
    case UserRole.schoolAdmin:
      return 'school_admin';
  }
}


export function parseJwt (token: string): {uuid: string} {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
