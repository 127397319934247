export const rootDomain = 'prosv.ru';
export const skyengDomain = '';
export const apiSubdomain = 'prod.hw-backend';
export const billingDomain = 'media.prosv.ru';
export const cmsDomain = 'prod.hw-cms';

export const environmentCustom = {
  name: 'Production Environment',
  app: {
    production: true,
    buildNumber: '1',
  },
  serverLogger: {
    enabled: true,
  },
  sentry: {
    dsn: '',
  },
  crypto: {
    iv: 'xnMcr9WXXaSnzyph',
    key: 'A2B5EE3A4B73F8FCDA27FA32DA4EDBC8',
  },
  thirdpartyServices: {
    ga: {
      appId: 'GTM-T3NFXRJ',
      appIdTwo: 'GTM-MZXHZFP'
    },
    ym: {
      appId: '56432026',
    },
    prosvId: {
      host: 'https://id.prosv.ru',
      consumer: '43a46eac-bb8a-4ce4-a660-fc5905caa48b',
    },
    paymentSdk: {
      serviceToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdG9yZV9kZXBhcnRtZW50X2lkIjo3NjM1NCwic3RvcmVfZGVwYXJ0bWVudF9rZXkiOjkwNjA3MDAsInBpbl9jb2RlIjoxMzA2fQ.O_Nb75tUqIRRNIZDxMbC3vHDR3-xM3Yr7RwDiGx1ocs',
      mode: 'prod',
    },
    vc: {
      login: `https://${billingDomain}/vk/`,
    },
    microSentry:{
      dsn: 'https://e6c12ff7d9464f7e95bf60629714fa5c@gt-hw.prod.yc.prosv.ru/15'

    }
  },
  tracking: {
    logEvents: true,
    hits: false,
  },
  taskEncKey: `-----BEGIN RSA PRIVATE KEY-----
MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCmBNMA8EIc9pkX
wwD4cYaCBKkAwA4Rz+UMfZC+XnLPkvw98VxkrYWJhW9CqiWxDv7yZzJXOLiRYrbs
CMnuYKaZhJZHdcEwNn5TAMFkdAq1jXNCOcCMNPvi3qMEWA1kutUD+eCSEuNP0Op7
FQtHIb0poNTNcMDjOM2ThiSr4mCT9+mFxgDI8FJqYIJlr22Jr5AQ6eXeS88yt7tJ
oJTq7J5JyDdCvcznddziHD68l2k7lHkDK4uMn3jIgkG0yMLdsLh/d6oehbG7INs1
5lt+iFmpbUVuNA3YFwhzVBVZx5NuCgg6K2bPRPB9QXjn5l8A1+dw4gChAUerJ/WO
uQ7tKc9TAgMBAAECggEAQush5Dhz5bnOlsHfE8Wq2qCnQKqpYSuj30eDvwBSFfJg
41T1EY3SDE8xPDVaMQbi4/GN3JN77fpU0kpunrvtm2bSrmY8I0Nr4ugki+msYWbt
RQ4DW2CGAXoNSpR1fZtDfrFLgIXFg2gqA8fFPnxOg8eUz2R6E3zTfHnvMSt0E2AN
2enyKW+ZaigeU15XhECGgzS4JKK0DgbTvkt2uVpNGPchfbTTLxBsJHeNCVzksuck
DXmbvAbHChy2ziC6WGgzFMwlWucd2rfsRKzZSq8AOgqMNOIniSWAaywyc+XSH0d9
pfP9BmWGKs4tdSoEpumrIlH+E41F1sHWPNqePerEzQKBgQDNwG8AeN0QAIBtYSMT
770Z32QB/U+jakAYM9ePLXjBS1aCn+6xKCqtHgjKp+XjpHxfuHv4ZJj09L/3SSKZ
aYU1oPDRgB/S/ruiVhJeNZ8wLufx+aeV2wzTu1nkbPvrsnDznC5vqz96UPrUBnuY
odu6JFfa3E2aLwM+8IZ+tZhP5QKBgQDOkExxNov9gjpvfMbk2CXYXq4vuecwOCMJ
oNkL0Wpmi03g9D+WR7xiVsopOWYBOpYThQnBpa676NlIf5Z6OereOviTxiZ78Yml
RWW8d4G3nWQRuPQPfQhAqTjGGKYrqfbZuxkrxywWxvp01uDiebCrdJTNvtDrytix
arw9wHx+1wKBgDFf34NefbYsWKVm7RMIsfl2eTpc/nJ5ud3U+wKZ5+OC38TC+nW6
7NrUXUm8ltdVDp+t1toZ0fbekP6Xp1znTJc9Au1TcM+iX4aqXwgKmCPo7bJMteGp
Sl+5Eu5VFPNPw/lB3obfsoD40+hzkc9j8CWwOQEFYv4AEChXcf8QNvfdAoGAdM4O
G9rPcGvqpU52ncNIK0Dm2KAUBiVIUtJ8yX0C4LeXM0SKjG1GLomLSaJmdv1tDd6l
9LW+yA1UiPV03urPUBkipVVgP6gGwMksTVV6qZy7kHX4tKVsPDw0mXgoxvoWV2rA
Qgwfd0XPLLCxe/96Eooa9vkW5K7QuAp2Jakp/QMCgYBtqf+eZPWMO83b/2q7oc44
e9gtiRe8phGlVjHlRnQEApUSykg9zsElQ/hYdBBn5ARZ8egbWEQO1rPnZuaxXgO0
kQvzXmtLRN8X6rsoDeAz1kHK4fP+yvEMu9XJYhkm/j/Vi67cyjCqU2zsWVJHa5G1
a7CI8/+PtIGo6EZZXOugCw==
-----END RSA PRIVATE KEY-----`
};
