export enum UserRole {
  teacher = 'ROLE_EDU_SKYSMART_TEACHER_USAGE',
  student = 'ROLE_EDU_SKYSMART_STUDENT_USAGE',
  schoolAdmin = 'ROLE_EDU_SKYSMART_SCHOOL_ADMIN_USAGE',
}

export enum UserAuthState {
  guest = 'guest',
  partiallyRegistered = 'partiallyRegistered',
  fullyRegistered = 'fullyRegistered',
}

export const OFFER_LAST_UPDATED_AT = new Date('2021-08-01T00:00:00+00:00');

export enum UserConfigStatus {
  new = 'new',
  registered = 'registered',
}

export enum UserGrade {
  unknown = 'unknown',
  primary = 'primary',
  middle = 'middle',
  high = 'high',
}

export enum GeolocationWebPermission {
  allow = 'allow',
  deny = 'deny',
}


export const ProsvUuidKey = 'ProsvUuid';
