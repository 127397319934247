import {Renderer2, RendererFactory2,  Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {environment} from "../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {LectaLocalStorageService} from "@lecta/core/local-storage";
import {CurrentUserService} from "../../user/core/service/current-user";
import {take} from "rxjs/operators";
import {of, switchMap} from "rxjs";
import {ProsvIdUrlBuilderService} from "../../integrations/prosv/service/prosv-id-url-builder.service";
import {ProsvIdAuthService} from "../../integrations/prosv/service/prosv-id-auth.service";
import {UserRegistryService} from "../../user/core/service/user-registry";
import {AuthService} from "../../user/core/service/auth";
import {SessionStorageService} from "@lecta/core/service/session-storage";
import {REDIRECT_URL_PATH} from "../consts";
import {ProsvUuidKey} from "../../user/core/const";

@Injectable({ providedIn: 'root' })
export class IdAuthScriptService {
  private SCRIPT_PATH = `${environment.thirdpartyServices.prosvId.host}/api/state.js`;
  private renderer: Renderer2;
/*  private doubleState = false;*/

  constructor(
    private router: Router,
    private rendererFactory: RendererFactory2,
    private ls: LectaLocalStorageService,
    private currentUserService: CurrentUserService,
    @Inject(DOCUMENT) private document: Document,
    private prosvIdUrlBuilderService: ProsvIdUrlBuilderService,
    private activateRoute: ActivatedRoute,
    private prosvIdAuthService: ProsvIdAuthService,
    private authService: AuthService,
    private userReg: UserRegistryService,
    private sessionStorageService: SessionStorageService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Append the JS tag to the Document Body.
   * @param src The path to the script
   * @returns the script element
   */
  private loadJsScript( src: string ): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  connectScript(): void{
    const scriptElement = this.loadJsScript(this.SCRIPT_PATH);
    scriptElement.onload = () => {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const api = new IdPState({redirectMethodOnFail:true});
      api.periodic((state: any) => {
        const isEmpty = (obj: Object | null): boolean => {
          for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
              return false;
            }
          }
          return true;
        }

        if(state.authorized() === false && !isEmpty(this.ls.get('edu-token-collection')) && !this.authService.getToken()){
          this.currentUserService.getProviderFromUser$.pipe(take(1)).subscribe((provider) =>{
            if(!(provider === 'mediateka' || provider === 'mos_oblast')) {
              this.router.navigate(['/logout']);
            }
          });
        }
        if(state.authorized() === false && isEmpty(this.ls.get('edu-token-collection')) && !this.authService.getToken()){
          this.currentUserService.user$.pipe(take(1)).subscribe(value => {
            if(value){
              this.router.navigate(['/logout']);
            }
          });
        }

        if(state.authorized() === false &&  this.authService.getToken() ){
          this.currentUserService.getProviderFromUser$.pipe(take(1)).subscribe((provider) =>{
            if(provider === 'prosv_id') {
              window.location.href = this.prosvIdUrlBuilderService.getLogoutUrl();
            }
          });
        }

        if(state.authorized()) {
          this.currentUserService.getProviderFromUser$.pipe(
            take(1),
            switchMap((provider) => {
              const value = (provider === 'mediateka' || provider === 'mos_oblast');
              if (value) {
                if (!this.prosvIdUrlBuilderService.isProsvIdHost(document.referrer)) {
                  window.location.href = this.prosvIdUrlBuilderService.getLogoutUrlHref();
                }
                if (sessionStorage.getItem('reloaded')) {
                  return of(value);
                }
                sessionStorage.setItem('reloaded', 'true'); // лечение постоянных перезагрузок
                const returnUrl = this.activateRoute.snapshot.queryParams.returnUrl;
                this.router.navigateByUrl(returnUrl, {replaceUrl: true});
                return of(value);
              }

              return of(value);
            })
          ).subscribe();
          const isVk = window.location.href.includes('provider=vk');
          // почему-то сразу недоступно состояние авторизации, даже если на них подписаться. Поэтому авторизуемся только
          // со второго раза
          // && this.doubleState
          if (!this.userReg.getCurrentUser() &&
            !sessionStorage.getItem('reloaded')) {
            sessionStorage.setItem('landing', 'true');
            sessionStorage.setItem('reloaded', 'true');
            this.setBackUrl();

            if('/prosv/auth' !== window.location.pathname && !isVk){
              this.prosvIdAuthService.openProsvIdAuth({ role: null });
              return;
            }

          }

          const prosvUuid = this.ls.get(ProsvUuidKey);
                if (prosvUuid){
                if (prosvUuid && state.actual().uuid() && prosvUuid !== state.actual().uuid()) {
                  this.setBackUrl();
                  this.prosvIdAuthService.openProsvIdAuth({role: null});
                }
            }
        }
      }, 1000);
    }




    scriptElement.onerror = () => {
      throw new Error('Script load error');
    }
  }

  redirectToMain(): void{
    this.router.navigate(['/']);
  }

  private setBackUrl(): void{
    if ('/prosv/auth' !== window.location.pathname &&
      '/logout' !== window.location.pathname &&
      '/' !== window.location.pathname) {
      this.sessionStorageService.set(REDIRECT_URL_PATH, window.location.pathname);
    }
  }
}
