import { Inject, Injectable } from '@angular/core';
import { LectaApiService } from 'core/api';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  ProsvIdAuthData, ProsvIdAuthenticationFailCode,
  ProsvIdAuthenticationSuccessResponse,
  ProsvIdAuthorizationResponse,
  ProsvIdAuthState,
} from '../interface';
import {catchError, map} from 'rxjs/operators';
import { DecorateUntilDestroy, takeUntilDestroyed } from 'core/rxjs';
import { DOCUMENT } from '@angular/common';
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {ProsvUuidKey} from "../../../user/core/const";
import {LectaLocalStorageService} from "@lecta/core/local-storage";

@Injectable({ providedIn: 'root' })
@DecorateUntilDestroy()
export class ProsvIdAuthService {
  private endpoints = [environment.endpoints.api];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: LectaApiService,
    private router: Router,
    private localStorage: LectaLocalStorageService
  ) {}

  getProsvIdAuthUrl(stateParams?: ProsvIdAuthState | null): Observable<ProsvIdAuthData> {
    return this.apiService.post<ProsvIdAuthData>(this.endpoints, '/api/v1/auth/prosv/login-state', {
      params: { ...stateParams },
      retry: 1,
    });
  }

  authenticate(code: string, state: string): Observable<ProsvIdAuthenticationSuccessResponse> {
    return this.apiService.get<ProsvIdAuthenticationSuccessResponse>(
      this.endpoints,
      '/api/v1/auth/prosv/authenticate',
      {
        params: { code, state },
      },
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        if(err.error.code === ProsvIdAuthenticationFailCode.parentNotAllowed){
          throw err;
        }else{
          this.router.navigate(['/403']);
          throw 'error in source. Details: ' + err;
        }
      }),
    );
  }

  authorize(token: string, role: string): Observable<ProsvIdAuthorizationResponse> {
    return this.apiService.post<ProsvIdAuthorizationResponse>(this.endpoints, '/api/v1/auth/prosv/authorize', {
      params: { token, role },
      retry: 1,
    }).pipe(
      catchError(err => {
        this.router.navigate(['/403']);
        throw 'error in source. Details: ' + err;
      }),
    );
  }

  openProsvIdAuth(stateParams?: ProsvIdAuthState | null): void {
    this.localStorage.remove(ProsvUuidKey);
    this.getProsvIdAuthUrl(stateParams)
      .pipe(
        map(data => {
          if(data?.url.includes('&redirect_uri=')){
            this.router.navigate(['/403']);
            throw new Error('Зацикливание ссылки '+data.url);
          }
          return this.addReturnBackUrl(data.url);
        }),
        catchError(err => {
          this.router.navigate(['/403']);
          throw 'error in source. Details: ' + err;
        }),
        takeUntilDestroyed(this),
      )
      .subscribe(url => (this.document.location.replace(url)));
  }


  openProsvIdAuthWithSocialNetwork(socialNetworkProvider: string,stateParams?: ProsvIdAuthState | null): void {
    this.getProsvIdAuthUrl(stateParams)
      .pipe(
        map(data => {
          if(data?.url.includes('&redirect_uri=')){
            this.router.navigate(['/403']);
            throw new Error('Зацикливание ссылки '+data.url);
          }
          return this.addReturnBackUrl(data.url);
        }),
        catchError(err => {
          this.router.navigate(['/403']);
          throw 'error in source. Details: ' + err;
        }),
        takeUntilDestroyed(this),
      )
      .subscribe(url => (this.document.location.replace(`${url}&provider=${socialNetworkProvider}&silent=true`)));
  }

  addReturnBackUrl(url: string): string {
    const returnUrl = encodeURIComponent(`${this.document.location.origin}/prosv/auth`);

    return `${url}&redirect_uri=${returnUrl}`;
  }

  onSubmitPreferences(subject: number[], grade: number[]): Observable<void> {
    return this.apiService.put<void>(this.endpoints, '/api/v1/prosv/profile ', {
      params: {subject, grade}
    });
  }

  synchronizationPreferences(): Observable<void> {
    return this.apiService.get<void>(this.endpoints, '/api/v1/prosv/profile ', );
  }

  getPreferences<T>(): Observable<T>{
    return this.apiService.get<T>(this.endpoints, '/api/v1/prosv/profile-saved ' );
  }
}
