import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { LectaSchedulerModule } from 'core/scheduler';
import { AppComponent } from './component/app/app';
import { RoutingModule } from 'routing/module';
import { RouterModule } from '@angular/router';
import { ILectaApiConfig, LectaApiModule } from 'core/api';
import { environment } from 'environments/environment';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { TeacherAuthModule } from 'teacher/auth/module';
import { TrackingService } from 'tracking/service/tracking';
import { UtmService } from 'tracking/service/utm';
import { ProductFeaturesService } from './service/product-features';
import { TRACKING_TARGETS_PROVIDER } from 'tracking/const';
import { SkysmartTrackingTargetService } from 'tracking/service/skysmart-tracking-target';
import { IntegrationsService } from 'integrations/core/service/integrations';
import { NgSelectModule } from '@ng-select/ng-select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'user/core/service/auth-interceptor';
import { BuildVersionInterceptor } from 'user/core/service/build-version-interceptor';
import { SeoRoutingService } from 'routing/service/seo-routing.service';
import { LectaIconRepositoryModule } from 'lecta-ui/icon/lecta-icon-repository.module';
import { LectaDialogConfig } from 'lecta-ui/modal-dialog/lecta-modal-dialog.const';
import { IMaskModule } from 'angular-imask';
import { LectaNotificationModule } from 'lecta-ui/notification';
import { LectaMathmlConfig, LectaMathmlModule } from 'core/mathml';
import { LectaFileConfig, LectaFileModule } from 'core/file';
import { LectaDialogConfirmConfig } from 'lecta-ui/confirm-popup';
import { SimpleGaTrackingService } from 'tracking/service/simple-ga-tracking.service';
import { PopupModule } from 'ui/popup/module';
import { CmsAuthInterceptor } from '+cms/cms-auth/cms-auth-interceptor';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentSdkConfigModel, PaymentSdkModule, PaymentSdkService } from '@lecta/libs/payment-sdk';
import { LayoutModule } from '@angular/cdk/layout';
import {IdAuthScriptService} from "./service/id-auth-script";
import {GlobalErrorHandler} from "./error-handler";
import {TimeZoneHeader} from "../user/core/service/time-zone-header";
import {provideCacheableAnimationLoader, provideLottieOptions} from "ngx-lottie";
import {MultibookUserService} from "./service/multibook-user";

registerLocaleData(localeRu);

export function lectaApiConfigFactory(): ILectaApiConfig {
  return {
    requestAutorejectTimeoutMs: 60 * 1000,
  };
}

export function lectaMathmlConfigFactory(): LectaMathmlConfig {
  return {
    mathJaxConfig: {
      chtml: {
        scale: 1.5,
      },
      options: {
        renderActions: {
          addMenu: [0, '', ''],
        },
      },
    },
  };
}

function lectaFileConfigFactory(): LectaFileConfig {
  return {
    endpoint: environment.endpoints.api,
  };
}

function paymentSdkConfigFactory(): PaymentSdkConfigModel {
  const { mode, serviceToken } = environment.thirdpartyServices.paymentSdk;

  return {
    mode,
    serviceToken,
  } as PaymentSdkConfigModel;
}


@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    RoutingModule,
    LectaApiModule.forRoot(lectaApiConfigFactory),
    TeacherAuthModule,
    // Если не добавить сюда NgSelectModule, то ng-select не будет работать в компонентах,
    // используемых через PopupService.open
    // Пример проблемного окна: редактирование ученика в кабинете учителя
    // https://devjira.skyeng.ru/browse/EDU-6631
    NgSelectModule,
    LectaMathmlModule.forRoot(lectaMathmlConfigFactory),
    LectaFileModule.forRoot(lectaFileConfigFactory),
    LectaSchedulerModule.forRoot(),
    LectaNotificationModule,
    LectaIconRepositoryModule,
    IMaskModule,
    PopupModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    LayoutModule,
    PaymentSdkModule.forRoot(paymentSdkConfigFactory),

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: TRACKING_TARGETS_PROVIDER, useExisting: SkysmartTrackingTargetService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CmsAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BuildVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeZoneHeader, multi: true },
    { provide: LectaDialogConfig, useClass: LectaDialogConfirmConfig },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    IdAuthScriptService,
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideCacheableAnimationLoader(),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private trackingService: TrackingService,
    private utmService: UtmService,
    private integrationsService: IntegrationsService,
    private productFeatures: ProductFeaturesService,
    private seoRoutingService: SeoRoutingService,
    private simpleGaTrackingService: SimpleGaTrackingService,
    private paymentSdkService: PaymentSdkService,
    private idAuthService: IdAuthScriptService,
    private multibookService: MultibookUserService
  ) {
    this.multibookService.init();
    this.utmService.storeUtms();
    this.trackingService.init();
    this.trackingService.setUtms(this.utmService.filledUtms);
    this.integrationsService.init();
    this.productFeatures.init();
    this.seoRoutingService.start();
    this.simpleGaTrackingService.init();
    this.paymentSdkService.init();
    this.idAuthService.connectScript();
  }

}
