import { Injectable } from '@angular/core';
import { filterEmpty } from 'core/rxjs';
import { Store } from 'core/store';
import Cookies from 'js-cookie';
import {GeolocationWebPermission, OFFER_LAST_UPDATED_AT, ProsvUuidKey, UserRole} from 'user/core/const';
import {
  IJwtData,
  IJwtUser,
  ProviderType,
  TeacherNewWorkbookInfoResponse,
  UserConfig,
  UserFeatures
} from 'user/core/interface';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { UsersApi } from '../api/users';
import { isPrerenderMode } from 'core/misc';
import {TeacherApi} from "../api/teacher";
import {parseJwt} from "../helper";
import {LectaLocalStorageService} from "@lecta/core/local-storage";
import {Router} from "@angular/router";
import {MultibookUserService} from "../../../app/service/multibook-user";



interface CurrentUserStore {
  user: IJwtUser | null;
  config: UserConfig | null;
  isConfigLoading: boolean;
}

const STORE_INITIAL_STATE: CurrentUserStore = {
  config: null,
  isConfigLoading: false,
  user: null,
};
const IS_QA_USER_COOKIE = 'is_qa_user';
const ROLE = 'role';
const PROSVET_SELECTED_CONTENT_USER_IDS: number[] = [9223653, 9236913];
const SKYSMART_STEP_APPROVER_IDS = [
  12580994, // meth.check@test.ru
];

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  private store = new Store(STORE_INITIAL_STATE);
  existingConfig$ = this.store.select('config').pipe(filterEmpty());
  features$: Observable<UserFeatures>;
  userChanged$: Observable<IJwtUser | null>;
  user$ = this.store.select('user');
  authorizedUser$ = this.store.select('user').pipe(filterEmpty());

  isAnonymousProviderUser$ = this.store.select('config').pipe(
    filterEmpty(),
    map(config => config.providerType === 'mediateka'),
  );

  isMashMo$ = this.store.select('config').pipe(
    filterEmpty(),
    map(config => config.providerType === 'mos_oblast'),
  );

  getProviderFromUser$= this.store.select('config').pipe(
    filterEmpty(),
    map(config => config.providerType),
  );

  constructor(private usersApi: UsersApi,private teacherApi: TeacherApi,private localStorage: LectaLocalStorageService,private router: Router,private multibookService: MultibookUserService) {
    this.features$ = this.store.select('config').pipe(
      filterEmpty(),
      map(config => config.features),
      distinctUntilChanged(),
    );

    this.userChanged$ = this.user$.pipe(
      distinctUntilChanged((prev, next) => {
        // in case prev & next are set compare by userId
        if (prev && next) {
          return prev.id === next.id;
        }

        // otherwise pass if prev = null and next != null or vise versa
        return !prev && !next;
      }),
    );

    this.userChanged$
      .pipe(
        switchMap(user => {
          if (isPrerenderMode()) {
            return of({ features: {}, hasPassword: false, isTest: false, createdAt: '', userId: 0 });
          }

          if (user) {
            return this.fetchConfig().pipe(catchError(() => of(null)));
          }

          return of(null);
        }),
        startWith(null),
        tap(config => {
          this.store.updateFields({ config });
          this.setLocalStorageProsvUuid(config as UserConfig);
        }),
      )
      .subscribe(res=>{
        if(this.getUser()?.roles.includes('ROLE_EDU_SKYSMART_TEACHER_USAGE') && res &&
          !(res as UserConfig)?.features?.gradeAndSubjectSelectionPage &&
          (res as UserConfig)?.providerType !== "mediateka" &&
          (res as UserConfig)?.providerType !== "mos_oblast" &&
          !this.multibookService.isMultibookUser()){
          this.router.navigate(['prosv/subjects'],{
            queryParams: { isTeacher: true}
          })
        }
        // todo убрать этот костыль с запросом и с отчеством
        const userConfig = res as UserConfig;
        if(!this.getUser()?.patronymic && userConfig?.patronymic) {
          this.setCurrentUser({...this.getUser(), patronymic: userConfig.patronymic} as IJwtUser);
        }
        const isMash = this.getMashRole() === 'student';
        if(res && userConfig?.trialExpiredDate === null && this.checkCreatedDateTwoDays(userConfig?.createdAt) && !isMash ){
          setTimeout(()=>{
            // eslint-disable-next-line rxjs/no-nested-subscribe
            this.refreshConfig().pipe(take(1)).subscribe()
          },2000)
        }

      });

  }

  private setLocalStorageProsvUuid(config: UserConfig) {
    if (config) {
      const prosvToken = config?.prosvUserData;
      if (prosvToken) {
        const uuid = parseJwt(prosvToken).uuid;
        this.localStorage.set(ProsvUuidKey, uuid)
      }
    }
  }

  setCurrentUser(jwtUser: IJwtUser | null): void {
    this.store.updateField('user', jwtUser);
  }

  getUserProvider(): null |  ProviderType{
    const config = this.store.get('config');
    return config?.providerType || null;
  }


  getUser(): IJwtUser | null {
    return this.store.get('user');
  }

  hasRole(role: string): boolean {
    const user = this.getUser();

    return !!user && user.roles.includes(role);
  }

  isTeacher(): boolean {
    return this.hasRole(UserRole.teacher);
  }

  isStudent(): boolean {
    return this.hasRole(UserRole.student);
  }

  getClassNumber(): Observable<number | undefined> {
    return this.getConfig().pipe(map(({ classNumber }) => classNumber));
  }

  getLoggedInUserHash(): string {
    const user = this.getUser();

    return user && user.email ? user.identity : '';
  }

  isQAUser(): boolean {
    return !!Cookies.get(IS_QA_USER_COOKIE) || false;
  }

  getMashRole(): string | undefined {
    return Cookies.get(ROLE);
  }

  isProsvetSelectedContentUser(): boolean {
    const user = this.getUser();
    return (user && PROSVET_SELECTED_CONTENT_USER_IDS.includes(user.id)) || false;
  }

  isStepApproverUser(): boolean {
    const user = this.getUser();
    return (user && [...PROSVET_SELECTED_CONTENT_USER_IDS, ...SKYSMART_STEP_APPROVER_IDS].includes(user.id)) || false;
  }

  getFeatures(): UserFeatures | null {
    const config = this.store.get('config');
    return config?.features || null;
  }

  getUserUuid(): string{
    const prosvUserData = this.getConfigRaw()?.prosvUserData;
    if(prosvUserData){
      return parseJwt(prosvUserData)?.uuid ?? '';
    }
    return '';
  }

  createFeature<T extends keyof UserFeatures>(featureName: T, featureValue: UserFeatures[T]): Observable<void> {
    if (isPrerenderMode()) {
      return EMPTY;
    }
    return this.usersApi.createFeature({ featureName, featureValue }).pipe(
      // update current user features
      tap(() => this.setFeature(featureName, featureValue)),
    );
  }

  getTeacherNewWorkbookInfo(): Observable<TeacherNewWorkbookInfoResponse[]> {
    return this.teacherApi.getUserConfig();
  }

  getConfig(): Observable<UserConfig> {
    return this.store.select().pipe(
      filter(state => !state.isConfigLoading),
      map(state => state.config),
      filterEmpty(),
      take(1),
    );
  }

  getConfigRaw(): UserConfig | null {
    return this.store.get('config');
  }

  getRegisteredAt(): Observable<Date> {
    return this.getConfig().pipe(map(config => new Date(config.createdAt)));
  }

  getIsOfferAccepted(): Observable<boolean> {
    return this.getConfig().pipe(
      map(config => {
        if (config.lastOfferAcceptedAt) {
          return +new Date(config.lastOfferAcceptedAt) > +OFFER_LAST_UPDATED_AT;
        }

        return false;
      }),
    );
  }

  refreshConfig(): Observable<UserConfig> {
    return this.fetchConfig().pipe(tap(config =>
    {
      this.store.updateFields({ config });
      this.setLocalStorageProsvUuid(config as UserConfig);
    }));

  }

  createOrUpdateFeature<T extends keyof UserFeatures>(featureName: T, featureValue: UserFeatures[T]): Observable<void> {

    if (isPrerenderMode()) {
      return EMPTY;
    }
    return this.usersApi.createFeature({ featureName, featureValue }).pipe(
      // update current user features
      tap(() => this.setFeature(featureName, featureValue)),
    );
  }

  getMainRole(): UserRole | null {
    const user = this.getUser();
    if (!user) {
      return null;
    }

    return this.extractMainRoleFromUser(user);
  }

  extractMainRoleFromUser(user: IJwtUser | IJwtData): UserRole {
    return user.roles[0]! as UserRole;
  }

  isGettingGeolocationAllowed(): Observable<boolean> {
    return this.getConfig().pipe(
      map(config => config.gettingGeolocationWebPermission === GeolocationWebPermission.allow),
    );
  }

  isGettingGeolocationDenied(): Observable<boolean> {
    return this.getConfig().pipe(
      map(config => config.gettingGeolocationWebPermission === GeolocationWebPermission.deny),
    );
  }

  private fetchConfig(): Observable<UserConfig> {
    this.store.updateField('isConfigLoading', true);
    return this.usersApi.getUserConfig().pipe(
      catchError(error => {
        if (error.error && error.error.code === 401) {
          localStorage.clear();

          window.location.assign(window.location.origin);
        }

        return throwError(error);
      }),
      finalize(() => {
        this.store.updateField('isConfigLoading', false);
      }),
    );
  }

  private setFeature(featureName: keyof UserFeatures, featureValue: UserFeatures[keyof UserFeatures]): void {
    this.store.update(state => {
      if (state.config === null) {
        return state;
      }

      return {
        ...state,
        config: {
          ...state.config,
          features: {
            ...state.config.features,
            [featureName]: featureValue,
          },
        },
      };
    });
  }

  private checkCreatedDateTwoDays(createdAt: string): boolean {
    const result = new Date();
    result.setHours(0,0,0,0);
    const createdAtDate =  new Date(createdAt.split('T')[0]);
    createdAtDate.setDate(createdAtDate.getDate() + 2);
    return  createdAtDate.getDate() >= result.getDate();
  }
}
